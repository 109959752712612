#ht h3 {
    margin-bottom: 1rem;
}
#ht .presta {
    transition: var(--transition);
} 
.ht__presta {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.5rem;
}
.presta {
    min-height: 250px;
    height:100%;
    width:350px;
    border: 1px solid var(--color-secondary);
    border-radius:1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}
.price {
    margin-bottom: 1rem;
}
.price svg {
    font-size: 1.4rem;
}
.price span {
    margin-left: 1rem;
    margin-right: 1rem;

}
.detail {
    overflow: hidden;
    text-overflow: ellipsis;
}

/* ================ TABLETTE ============ */
@media screen and (max-width: 768px) {

}

/* ================ PHONE ============ */
@media screen and (max-width: 480px) {
    .ht__presta {
        grid-template-columns: repeat(1, 1fr);
        gap: 2.5rem;
    }

}