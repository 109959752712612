nav {
    background: var(--color-primary-transparent);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    /* border: 2px solid var(--color-secondary); */
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text);
    font-size: 1.1rem;
    font-weight: bold;
}
nav a span {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
}

nav a:hover {
    color: var(--color-secondary);
}

nav a.active {
    color: var(--color-secondary);
}

.nameNav {
    padding-left: 0.3rem;
}

/* ================ TABLETTE ============ */
@media screen and (max-width: 1024px) {
    .nameNav {
        display: none;
    }
}
    
/* ================ PHONE ============ */
@media screen and (max-width: 600px) {
    .nameNav {
        display: none;
    }
}
