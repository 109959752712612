#reflexiology h2 {
padding-top: 2rem;

}
.types {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-style: italic;
    margin: 30px 0 50px 0;
}
.types h3 {
    padding: 0.5rem;
}

/* Reflexology General */

.reflexiology__general {
    display: flex;
    flex-direction: row ;
    margin-top: 1rem;
}
.reflexiology__general img {
    width: 40%;
    max-height: 500px;
}
.reflexiology__general .general__content {
    padding: 0 7rem;
}

/* Accompagnement */

.reflexiology__a {
display: flex;
flex-direction: row;
}
.acc__content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.acc__content p {
    padding: 2rem 4rem;
}
.img__a {
    object-fit: cover ;
    overflow: hidden;
    margin-bottom: 0;
    max-height: 500px;
}


/* reflexiology perinatal*/
.reflexiology__b {
    display: flex ;
    flex-direction: row-reverse ;
    gap: 2.5rem;
    margin-top: 1rem;
}


/* reflexiology shiatsu */




/* ================ TABLETTE ============ */
@media screen and (max-width: 820px) {
    #reflexiology {
        display:flex;
        align-items: center;
        flex-direction: column !important;
    }
    #reflexiology img {
        width: 100%;
        object-fit: cover;
    }
    .types {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    /* Reflexology General */
    .reflexiology__general .general__content {
        padding: 0;
    }
    .reflexiology__general {
        flex-direction: column ;
    }
    /* Accompagnement */

.reflexiology__a {
    flex-direction: column;
    }
    .acc__content p {
        padding: 1rem 1rem;
    }
    /* reflexiology perinatal*/
.reflexiology__b {

    flex-direction: column ;

}
}

/* ================ PHONE ============ */
@media screen and (max-width: 480px) {

}