#footer {
    margin-bottom: 150px;
    display: flex;
    justify-content: center;
}
#footer a {
    text-decoration: none;
    color: var(--color-text);
}
.permalinks {
    display: flex;
    flex-direction: row;
    text-align: center;
}
.footer__socials {
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
}
.footer__socials a {
    font-size: 2rem;
    color: var(--color-secondary);
    margin: 2rem;
}

.permalinks li {
    padding: 1rem;
}
.footer__copyright {
    display: flex;
    justify-content: center;
}
/* ================ TABLETTE ============ */
@media screen and (max-width: 820px) {
    .permalinks {
        display: flex;
        flex-direction: column;
    }
    .permalinks li {
        padding: 1rem;
    }
}

/* ================ DEKSTOP ============ */
@media screen and (max-width: 480px) {

}