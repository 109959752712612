
.about__container {
    background: var(--color-primary);
    color: var(--color-text);
    display:flex;
    align-items: center;
    flex-direction: column;
    text-align: justify;
}
.about__general {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.about__general img {
    width: 40%;
    margin: 1rem 5rem 5rem 1rem;
}
.content__text__bottom {
    flex-direction: row;
}



/* ===================== ME ========================== */


/* ===================== CONTENT ========================= */
.content__text__bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 1rem;
}
.socialNetwork a {
    padding: 1rem 2rem 1rem 1rem;
    font-size: 2.5rem;
    color: var(--color-secondary);
    transition: var(--transition); 
}
.socialNetwork a:hover {
    color: var(--color-tercary);
}

.contactBtn a {
    margin: 1rem;
}

/* ================ TABLETTE ============ */
@media screen and (max-width: 820px) {
    .about__general {
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .about__general img {
        width: 50%;
        margin: 1rem;
    }
    .content__text__bottom {
        flex-direction: column;
    }
}

/* ================ DEKSTOP ============ */
@media screen and (max-width: 480px) {

}