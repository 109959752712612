#memory {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1rem;
    align-items: center;
}
#memory h2 {
    padding: 1rem;
}
.img__memory {
    width: 50%;
    object-fit: cover;
}




/* ================ TABLETTE ============ */
@media screen and (max-width: 820px) {
    #memory {
        flex-direction: column;
    }
    #memory img {
        width: 100%;
    }
}

/* ================ PHONE ============ */
@media screen and (max-width: 480px) {

}