.carousel__title {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.carousel__title img {
    padding-top: 1rem;
    width: 30px;
}
.carousel__content {
    height: auto;
    margin: auto 0;
}

.dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}
.dot {
    margin: 5px;
    cursor: pointer;
    font-size: 25px;
    color: black;
}
.slider {
    width: 100%;
    height: 280px;
    position: relative;
}
.slide {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: var(--transition);
}

.left__arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 32px;
    font-size: 35px;
    color: var(--color-secondary);
    z-Index: 1;
    cursor: pointer;
}
.right__arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 32px;
    font-size: 35px;
    color: var(--color-secondary);
    z-Index: 1;
    cursor: pointer;
}

/* ================ DEKSTOP ============ */
@media screen and (min-width: 1024px) {
    .slider {
        height: 600px;
    }
}