#massages {
    background: var(--color-primary);
    margin-top: 1rem;
}
.massages {
    display: flex;
    flex-direction: row !important;
    gap: 2.5rem;
}
.massages__sensitif {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2.5rem;
    border-radius: 2rem;
}
.acc__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.massages__aromatouch {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2.5rem;
}
.aro__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.content {
    max-width: 700px;
}
.img__massage__little {
    max-width: 600px;
}
.img__massage {
    max-height: 700px;
    object-fit: cover;
}

/* ================ TABLETTE ============ */
@media screen and (max-width: 820px) {
    .massages__sensitif {
        flex-direction: column;
    }
    .aro__content {
        flex-direction: column;
    }

}

/* ================ PHONE ============ */
@media screen and (max-width: 480px) {


    
}