
.contact__general {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact__content {
    display: flex;
    flex-direction: column;
    width: 30%;
    background: var(--color-primary);
    padding: 1.2rem;
    margin: 1rem;
    border-radius: 1.2rem;
    align-items: center;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__content:hover {
    background: transparent;
    border-color: var(--color-secondary);
}

.contact__icon {
    font-size:2rem;
    margin-bottom: 0.5rem;
}
.contact__content h4 {
    font-size: 18px;
    padding-top: 0;
}
.contact__content h5 {
    font-size: 14px;
}
.contact__content a {
    color: var(--color-secondary);
    font-weight: bold;
}


/* ================ TABLETTE ============ */
@media screen and (max-width: 820px) {
    .contact__general {
        display: flex;
        flex-direction: column;
    }
    .contact__content {
        width: 90%;
    }
}

/* ================ DEKSTOP ============ */
@media screen and (max-width: 480px) {


    
}