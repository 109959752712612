@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}

:root {
    --color-bg: #f5f5f5;
    --color-primary: #B5DDB9;
    --color-primary-transparent: rgba(181, 221, 185, 0.6);
    --color-secondary: #A66C6E;
    --color-tercary: #F2E7E5;
    --color-text:#005493;
    --color-text-dark: black;
    --color-text-light: #f5f5f5;

    --transition: all 1000ms ease-out;


    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 100%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    letter-spacing: -0.3px;

}

/* ============== GENERAL STYLES =============== */
section {
    width: var(--container-width-sm);
    margin: 0 auto;
    color: var(--color-text);
    max-width: 1400px;
}
h1,
h2,
h3,
h4,
h5 {
    padding-top: 1rem;
    text-align: center;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;

}
h2 {
    text-decoration: solid underline var(--color-secondary) 0.2rem;
    text-transform: uppercase;
}
p {
    padding: 1rem;
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-secondary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-secondary);
    transition: var(--transition); 
}
.btn:hover {
    background: var(--color-secondary);
    color: var(--color-bg);
    border-color: transparent;
}


img {
    display: block;
    width: 100%;
}


/* ================ TABLETTE ============ */
@media screen and (max-width: 820px) {

}

/* ================ DEKSTOP ============ */
@media screen and (max-width: 480px) {
    section {
        max-width: 1400px;

    }

}